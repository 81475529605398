<template>
  <div class="m-3 p-2 wrapper  wrapper--default">
    <h2 class="text-center">Openstaande bedragen totaal</h2>
    <Loading v-if="loading" />
    <div v-if="!loading">
      <h1>{{ open_amounts_total.length }}</h1>
      <Tabs>
        <Tab v-for="(seller, key) in open_amounts_total" :key="key" :title="key ? key : `Geen naam`">
          <OpenAmountsTable :table_data="seller" v-if="!loading" />
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import OpenAmountsTable from "@/components/OpenAmountsTable.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  components: { Loading, OpenAmountsTable, Tabs, Tab },
  data: () => ({
    open_amounts: null,
    loading: null,
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      request(`open-amounts`, "GET").then(({ open_amounts_total }) => {
        this.open_amounts_total = open_amounts_total;
        this.loading = false;
      });
    },
  },
};
</script>
